const state = () => ({
    web3: null,
    price: 0,
    userAddress: null,
    v1Instance: null,
    tokenInstance: null,
    stakingInstance: null,
    migrationInstance: null
});
export default state;
